<script>
import AuthService from '@/service/auth.service';
import { mapState } from 'vuex';

export default {
  name: 'profile',

  data() {
    return {
      loading: false,
      form: {},
    };
  },

  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.user,
    }),
  },

  methods: {
    async save() {
      this.loading = true;
      try {
        if (this.form.password !== this.form.rePassword) {
          return this.$toastr.error('Nova senha não confere');
        }

        await AuthService.changePasswordMe({
          password: this.form.password,
          oldPassword: this.form.oldPassword,
        });
        this.$swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Senha alterada com sucesso!',
          timer: 2000,
          showConfirmButton: false,
        });
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>Alterar senha</v-card-title>
        <v-card-text class="pt-5">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.oldPassword"
                  type="password"
                  label="Senha atual"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.password"
                  type="password"
                  label="Nova senha"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="form.rePassword"
                  type="password"
                  label="Confirme a nova senha"
                  color="white"
                  hide-details="auto"
                  outlined
                  persistent-placeholder
                  dense
                />
              </v-col>
            </v-row>
            <v-row justify="start" class="pt-4" dense>
              <v-btn class="ml-1" color="success" type="submit" :loading="loading" :disabled="loading">Salvar</v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>
